import {FormEventHandler, useState} from 'react';
import {IonButton, IonInput} from "@ionic/react";
import {Head, Link, usePage} from '@inertiajs/react';
import {useInertiaForm} from "use-inertia-form";
import {createValidator} from "@/utils";

const TaskcomLogin = () => {
    const {canResetPassword} = usePage().props as unknown as { status?: string, canResetPassword: boolean };

    const [showPassword] = useState(false);

    const {data, setData, post, processing, errors} = useInertiaForm({
        email: '',
        password: '',
        remember: true,
    });

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    const validateClass = createValidator<typeof data>(errors);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <Head title="ログイン"></Head>
            <div className="w-full max-w-xs">
                <h1 className="text-6xl font-bold text-center" style={{color: '#5AB2FF'}}>
                    Taskcom
                </h1>
                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                    <div className="rounded-md -space-y-px">
                        <div>
                            <IonInput
                                name="email"
                                type="email"
                                value={data.email}
                                onIonInput={(e) => setData('email', e.detail.value || '')}
                                placeholder="メールアドレス"
                                required
                                autocomplete="email"
                                className="rounded-t ion-padding-horizontal"
                            />
                        </div>
                        <div className="mt-2">
                            <IonInput
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={data.password}
                                onIonInput={(e) => setData('password', e.detail.value || '')}
                                placeholder="パスワード"
                                autocomplete="current-password"
                                {...validateClass('email', "rounded-b ion-padding-horizontal")}
                                required
                            />
                        </div>
                    </div>

                    <div>
                        <IonButton color="button" type="submit" expand="full" disabled={processing}>
                            ログイン
                        </IonButton>
                    </div>
                </form>
                {canResetPassword && (
                    <Link
                        href="/forgot-password"
                        className="mt-4 text-center text-sm text-blue-500 hover:underline block"
                        style={{color: '#5AB2FF'}}
                    >
                        パスワードを忘れた場合
                    </Link>
                )}
                <Link
                    href="/register"
                    className="mt-8 text-center text-sm text-blue-500 hover:underline block"
                    style={{color: '#5AB2FF'}}
                >
                    アカウントを作成する
                </Link>
            </div>
        </div>
    );
};

export default TaskcomLogin;


